.imageContainer {
  max-width: 80vw;
  margin: 40px auto;
}

.images {
  width: 100%;
  height: 300px;
}

.images img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
