:root {
  --gradient-background: -moz-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    rgba(4, 12, 24, 1) 25%
  );
  --gradient-text: linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #ae67fa -13.86%, #f49867 99.55%);

  --color-bg: #040c18;
  --color-footer: #031b34;
  --color-blog: #042c54;
  --color-text: #81afdd;
  --color-subtext: #ff8a71;
}

.wrapper {
  position: relative;
  color: #eee;
  min-height: 80vh;
  font-family: "Roboto";
  font-weight: 300;
  /* background-color: #130f40;
  background-image: linear-gradient(315, #000000 0%, #000000 74%); */
  background: rgb(2, 0, 36);
  background: linear-gradient(
    115deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 0, 0, 1) 35%
  );
}

.part1 {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 40px;
}

.content {
  max-width: 800px;
  font-weight: 300;
  color: var(--color-text);
}

.title {
  color: #a9d118;
  font-size: 50px;
  font-weight: 300;
  /* background: var(--gradient-text); */
  background: linear-gradient(135deg, #13f1fc, #0470dc);

  /* background: linear-gradient(135deg, #42e695, #3bb2b8);
  background: linear-gradient(135deg, #fad961, #f76b1c);
  background: linear-gradient(135deg, #13f1fc, #0470dc);
  background: linear-gradient(135deg, #79cbca, #e684ae); */
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.container {
  display: flex;
  justify-content: flex-end;
}

.part2 {
  width: 800px;
}

.part2 video {
  width: 100%;
  height: 100%;
}

/* .part2 video::before {
  content: "";
  background: rgba(0, 40, 83, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
} */

/* typing */
.text_typing {
  /* padding-left: 20px; */
  /* font-size: 20px; */
  font-weight: 200;
}

.text_typing {
  margin: 0px;
  white-space: nowrap;
  overflow: hidden;
  animation: typing 4s steps(17, end) forwards, 1s infinite;
}

@keyframes typing {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes blink {
  0%,
  100% {
    border-right: 2px solid transparent;
  }
  50% {
    border-right: 2px solid #eee;
  }
}

.button {
  position: relative;
  border: 0;
  display: inline-block;
  /* width: 12rem; */
  width: 15rem;
  height: auto;
  cursor: pointer;
  outline: none;
  vertical-align: middle;
  background-color: transparent;
  font-family: inherit;
}

.text {
  position: absolute;
  inset: 0;
  padding: 0.9rem 0;
  /* margin-left: 1.85rem; */
  margin-left: 1.85rem;
  color: #ffac30;
  /* line-height: 1.6; */
  line-height: 1.1;
  text-align: center;
  transition: all 0.4s ease-in-out;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 1px;
  /* background: var(--color-subtext); */
  background: linear-gradient(135deg, #13f1fc, #0470dc);

  /* background: linear-gradient(135deg, #13f1fc, #0470dc); */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 400;
  /* font-weight: bold; */
}

.circle {
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  /* background: #ffac30; */
  border-radius: 1.625rem;
  transition: all 0.4s ease-in-out;
  /* border-image-source: linear-gradient(to left, #743ad5, #d53a9d); */
  /* background: var(--color-subtext); */

  /* background: linear-gradient(135deg, #13f1fc, #0470dc); */
  background: linear-gradient(135deg, #13f1fc, #0470dc);
}

.icon {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  /* background: #fff; */
}

.arrow {
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
  transition: all 0.4s ease-in-out;
}

.arrow::before {
  content: "";
  position: absolute;
  top: -0.3rem;
  right: 0.0625rem;
  height: 0.625rem;
  width: 0.625rem;
  border-top: 0.125rem solid black;
  border-right: 0.125rem solid black;
  transform: rotate(45deg);
}

.button:hover .circle {
  width: 100%;
}

.button:hover .arrow {
  background-color: black;
  transform: translateX(1rem);
}

.button:hover .text {
  /* color: black; */
  /* font-weight: 400; */
  background: var(--color-footer);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 500px) {
  .title {
    font-size: 25px;
  }
}

@media (max-width: 340px) {
  .content {
    padding: 10px;
  }
}
