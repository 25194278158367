:root {
  --gradient-background: -moz-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    rgba(4, 12, 24, 1) 25%
  );
  --gradient-text: linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #ae67fa -13.86%, #f49867 99.55%);

  --color-bg: #040c18;
  --color-footer: #031b34;
  --color-blog: #042c54;
  --color-text: #81afdd;
  --color-subtext: #ff8a71;
  --bg-box: #042c54;
}

.wrapper {
  width: 90vw;
  margin: auto;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.2);
  border-radius: 10px;
}

body {
  font-family: "Roboto";
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 0 10px 0;
  padding: 10px 0 40px 0;
  /* color: #333; */
  border-radius: 5px;
  background: var(--gradient-text);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.underline {
  width: 90px;
  height: 5px;
  background: var(--color-subtext);
  /* background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.titleHeading {
  font-size: 60px;
  font-weight: 300;
  background: var(--gradient-text);
  /* background: linear-gradient(135deg, #42e695, #3bb2b8);
  background: linear-gradient(135deg, #fad961, #f76b1c);
  background: linear-gradient(135deg, #fad961, #f76b1c); */

  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* @media (max-width: 500px) {
  .titleHeading {
    font-size: 40px;
  }
} */

.point {
  line-height: 1.3;
}

.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  /* padding: 30px 20px; */
  align-items: center;
}

.points {
  display: flex;
  margin: 10px;
  max-width: 400px;
}

.iframe {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 300px;
  margin: auto;
}

iframe {
  /* width: 560px;
  height: 315px; */
  width: 100%;
  height: 100%;
  padding: 20px 0;
}

.point {
  /* color: hsl(229, 6%, 66%); */
  background: var(--color-text);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: rgb(35, 9, 37); */
  padding: 20px;
}

/* .joinbtn {
  background: #fd76cb;
  background: var(--color-text);
  background-clip: text;
  -webkit-text-fill-color: transparent;
} */

.pointIcon {
  color: #ffac30;
}

/* part2 */

.container2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
}

.aboutImage {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.aboutImage img {
  width: 400px;
  height: 300px;
  object-fit: cover;
}

.cardContainer {
  max-width: 400px;

  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 40px;
}

.cardContainer > * {
  margin: 20px 0;
}

.icons {
  width: 200px;
  background-color: #fffeff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 20px 20px;
  border-radius: 5px;
  font-size: 20px;
  display: flex;
  justify-content: space-around;
}

.join {
  padding: 20px 40px;
  border-radius: 40px;
  font-size: 20px;
  outline: none;
  border: none;
  /* background-color: #ffac30; */
  transition: transform 0.5s ease-in-out;
  font-family: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-subtext);
  color: var(--color-footer);
}

.join a {
  text-decoration: none;
  color: black;
}

.join:hover {
  transform: scale(0.9);
}

.content > * {
  margin: 20px 0;
}

.image {
  position: relative;
  display: flex;
  /* justify-content: center;
  align-items: center; */
  margin: auto;
  max-width: 500px;
  height: 300px;
}

/* .image img {
  width: 400px;
  height: 300px;
} */

/* .image__img {
  display: block;
  width: 400px;
  object-fit: cover;
} */

.imageContainer {
  max-width: 500px;
  height: 300px;
  /* padding: 20px; */
}

.imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.image__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: #ffffff;
  border-radius: 10px;
  font-family: "Quicksand", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.25s;
}

.image__overlay > * {
  transform: translateY(20px);
  transition: transform 0.25s;
}

.image__overlay:hover {
  opacity: 1;
}

.image__overlay:hover > * {
  transform: translateY(0);
}

.image__title {
  font-size: 2em;
  font-weight: bold;
}

.image__description {
  font-size: 1.25em;
  margin-top: 0.25em;
}

@media (max-width: 1200px) {
  .aboutImage img {
    width: 90%;
    height: 90%;
    object-fit: cover;
    transform: translate(0px, 0px);
  }

  iframe {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(0px, 0px);
  }

  .iframe {
    margin-top: 10px;
  }
}

@media (max-width: 900px) {
  /* .join {
    padding: 5px;
  } */

  .imageContainer {
    padding: 20px;
  }

  .container2 {
    grid-template-columns: repeat(1, 1fr);
  }

  .container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 400px) {
  /* .image img {
    width: 300px;
    height: 200px;
    padding: 10px 0;
  } */

  /* .image__overlay {
    width: 300px;
    height: 200px;
    padding: 10px 0;
  } */

  .iframe {
    width: 300px;
  }
}

/* @media (max-width: 300px) {
  .image__img {
    width: 280px;
  }
} */

@media (max-width: 340px) {
  .iframe {
    width: 250px;
  }
}
