body {
  font-family: "Roboto";
}

.wrapper {
  /* background-color: rgb(35, 9, 37); */
  color: #ccc;
  /* margin-bottom: 10px; */
  border-bottom: 1px solid white;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 80px 0 10px 0;
  padding: 10px 0 40px 0;
  color: #333;
  border-radius: 5px;
}

.underline {
  width: 90px;
  height: 5px;
  background-color: #ffac30;
}

.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* padding: 0 50px; */
  /* width: 90vw;
  margin: auto;
  background-color: #fff;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
  padding: 40px; */
}

.container .part1,
.part2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.container .part1 > * {
  margin: 20px 0;
}

.content {
  /* background: var(--color-text);
  background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: #ccc;
}

.borderRight {
  border-radius: 0 600px 600px 0;
  /* background-color: white; */
  background: var(--bg-box);
}

.borderLeft {
  border-radius: 600px 0 0 600px;
  /* background-color: white; */
  background: var(--bg-box);
}

.part2 {
  margin-left: 20px;
}

.eventTitle {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.timer {
  background-color: rgb(35, 9, 37);
  color: #eee;
  position: absolute;
  z-index: 100;
  padding: 30px 40px;
  border-radius: 10px;
  letter-spacing: 1px;
  font-size: 20px;
}

.image {
  width: 300px;
  height: 250px;
  z-index: 10;
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
/* extra */
.pointFlex {
  display: flex;
}

.pointsIcon {
  color: #ff8a71;
}
.image2 {
  transform: translateX(25%);
}

.image1 {
  transform: translateX(-25%);
}

.background {
  width: 300px;
  height: 200px;
  position: absolute;
  background: linear-gradient(to right, #ae67fa, #f49867);
  border-radius: 10px;
}

@media (max-width: 500px) {
  .image1,
  .image2 {
    width: 200px;
    /* transform: translateX(0); */
  }
}

@media (max-width: 1000px) {
  .container {
    grid-template-columns: repeat(1, 1fr);
  }

  .content {
    padding: 20px 0;
  }
}

@media (max-width: 400px) {
  .background {
    width: 200px;
  }
}

button {
  cursor: pointer;
  font-family: "Roboto", sans-serif;
}

.join {
  padding: 20px 40px;
  font-size: 20px;
  outline: none;
  border-radius: 40px;
  border: none;
  /* background-color: #ffac30; */
  transition: transform 0.5s ease-in-out;
  font-family: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  letter-spacing: 1px;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.1);
  background: var(--color-subtext);
  color: var(--color-footer);
}

.join:hover {
  transform: scale(0.9);
}
